module.exports = {
  introductionText:
    'Vyberte si typ nabídky a získejte relevantní CV-čka, zvýšení povědomí o firmě i nekonečnou slávu v komunitě. Jdeme na to?',
  badgeLabel: 'Nekonečné nabídky',
  priceNote: 'Cena bez DPH / nabídka',
  package: {
    exclusive: 'Exkluziv',
    exclusiveNote: '5840 Kč + DPH / nabídka',
    exclusiveSale: '299 € 7307 Kč',
    premium: 'Premium',
    premiumNote: '1930 Kč + DPH / nabídka',
    premiumSale: '99 € 2419 Kč',
    standard: 'Standard',
    standardNote: '1540 Kč + DPH / nabídka',
    standardSale: '79 € 1930Kč',
    complete: 'Komplet',
    category: 'Kategorie',
    title: '{{packageName}} balík',
    smallTitle: 'Malý {{packageName}} balík',
    mediumTitle: 'Stredný {{packageName}} balík',
    largeTitle: 'Nekonečný {{packageName}} balík',
  },
  addon: {
    companyLogo: 'Logo firmy',
    jobDuration: 'Trvání nabídky',
    topVisibility: 'TOP zobrazení - {{count}} dní',
    topVisibilityDescription: 'TOP zobrazení na webu',
    durationDays: '{{count}} dní',
    premiumVisibility: 'Prémiové zobrazení - {{count}} dní',
    standardVisibility: 'Základní zobrazení',
    newsletter: 'Zařazení do Newsletteru',
    newsletterShort: 'Newsletter',
    includedInNewsletter1x: '1x zařazení do Newsletteru',
    includedInNewsletter2x: '2x zařazení do Newsletteru',
    ppcCampaign: 'PPC online kampaň',
    ownColor: 'Zvýraznění v hodnotě {{price}} €',
    talentbox: 'Přístup do databáze životopisů',
    video: 'Video v nabídce',
    facebook: 'Facebook post',
    instagram: 'Instagram post',
    linkedin: 'LinkedIn post',
  },
  customPackageUpsell: {
    text: 'Potřebujete nekonečný balíček, nebo nabídku na míru?',
    cta: 'Napište nám',
  },
  selected: 'Vybráno',
  notebox: {
    headline: 'Hledejte kolegy výhodněji!',
    text: 'Kupte 5 nebo 10 jobů ve výhodném balíku a ušetřete až 30 %.',
    cta: 'Zobrazit ceník',
  },
  mostFavorite: 'NEJOBLÍBENĚJŠÍ',
  viewAdvantageousPackage: 'Zobrazit výhodní balík',
  unavailableForHRAgencies: 'Nedostupné pro HR agentury',
  tooltip1: 'Nabídka bude zveřejněna navždy. Můžete ji kdykoli uzavřít ve vašem profilu.',
  tooltip2: 'Odlište se od konkurence a podbarvěte si nabídku vlastní barvou.',
  tooltip3: 'Vyšší pozice na webu + logo',
  tooltip4: 'Základní pozice na webu',
  tooltipDb:
    'Na základě inzerované pozice vám vybereme relevantní uchazeče z Databáze životopisů',
  tooltip5: 'Nejvyšší pozice na webu + logo + barevné zvýraznění',
  packagesOfferHead: 'Výhodné balíky nabídek',
  smallPackage: 'Malý balík',
  mediumPackage: 'Stredný balík',
  infinitePackage: 'Nekonečný balík',
  smallStandardPackage: 'MALÝ ŠTANDARD BALÍK',
  mediumStandardPackage: 'STREDNÝ ŠTANDARD BALÍK',
  infiniteStandardPackage: 'NEKONEČNÝ ŠTANDARD BALÍK',
  smallPremiumPackage: 'MALÝ PRÉMIUM BALÍK',
  mediumPremiumPackage: 'STREDNÝ PRÉMIUM BALÍK',
  infinitePremiumPackage: 'NEKONEČNÝ PRÉMIUM BALÍK',
  smallExclusivePackage: 'MALÝ EXKLUZÍV BALÍK',
  mediumExclusivePackage: 'STREDNÝ EXKLUZÍV BALÍK',
  infiniteExclusivePackage: 'NEKONEČNÝ EXKLUZÍV BALÍK',
  promocodeTitle: {
    'malý balík': 'Malý balík',
    'nekonečný balík': 'Nekonečný balík',
    'stredný balík': 'Stredný balík',
    default: 'Promokód',
  },
  promocodeSubtitle: {
    exkluzív: 'Exkluziv',
    prémium: 'Premium',
    štandard: 'Standard',
  },
  help: {
    start: 'Potřebujete poradit, nebo máte dotazy?',
    contactUs: 'Napište nám',
    end: 'nebo zavolejte',
  },
  pill: {
    followers: '{{count}} sledovatelů',
    contacts: '{{count}} kontaktů',
    views: '{{count}}x více videní',
  },
  monthly: 'měsíčně',
  tooltipPpc:
    'Díky našim PPC kampaním na sociálních sítích Facebook a Instagram, stejně jako na Google Ads, dokážeme vaši nabídku dostat k ještě většímu počtu relevantních kandidátů. Minimální rozpočet na PPC kampaň je 30 € u PRÉMIUM nabídky a 60 € u EXKLUZÍV nabídky. Tímto způsobem můžete oslovit až 5krát více kandidátů.',
  tooltipNewsletter:
    'Pretlak Newsletter posíláme každé pondělní ráno našim uchazečům a freelancerům. Dohromady ho dostává více než 9000 kontaktů',
}
