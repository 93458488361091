module.exports = {
  noJobsYetContinueWithAddJob:
    'Zatím tady nic nemáte. Pokračujte kliknutím na tlačítko "Přidat job".',
  activeOffers: 'Aktivní nabídky',
  closedOffers: 'Uzavřené nabídky',
  viewCandidates: 'Zobrazit uchazeče',
  contactEmail: 'Kontaktní e-mail',
  contactEmailTooltip: 'Tento e-mail slouží k zasílání notifikací o nových zájemcích.',
  contactTelephone: 'Kontaktní telefonní číslo',
  whatDoWeOffer: 'Benefity',
  whatDoWeOfferPlaceholder:
    'Máte firemní snídaně, pet-friendly office nebo neomezenou dovolenou? Vždyť peníze nejsou všechno. (Odrážky!)',
  republishJobDescription:
    'Už jste jen krok od toho, aby byla vaše nabídka opět aktuální. Stačí si jen vybrat – STANDARD, nebo PREMIUM? Následně nabídku zkontrolujeme a do 24 hodin zveřejníme.',
  addJobDescription:
    'Vyberte si typ nabídky. Do 24-hodin ji zkontrolujeme a zveřejníme na webu. Získáte nekonečnou dobu trvání nabídky, vysokou relevanci CV-ček a zvýšení povědomí vaší firmy v naší komunitě! Jdeme na to?',
  addJobBadge: 'Nekonečné nabídky',
  companyNameLabel: 'Název firmy',
  companyNamePlaceholder: 'Pretlak - nepoužívejte s.r.o., spol. s r.o., a.s., ...',
  companyNameTooltip:
    'Napište název vaší značky/agentury/firmy tak, jak se bude při nabídce zobrazovat',
  jobCategoryLabel: 'Kategorie jobu',
  remotePositionLabel: 'Remote pozice',
  colabFormLabel: 'Forma spolupráce',
  salaryInGrossLabel: 'Mzda v hrubém',
  mentionOnlyLowerSalaryLimit: 'Uvádím jen spodní hranici mzdy',
  salaryNoteLabel: 'Poznámka ke mzdě',
  payOffTypeLabel: 'Způsob vyplácení',
  scopeOfWorkPlaceholder:
    'Jaká práce na uchazeče čeká? Doporučujeme psát v odrážkách, přímočaře a jednoduše. Podrobnosti si řeknete na pohovoru.',
  requirementsPlaceholder:
    'Jaké vzdělání, dovednosti nebo oblíbené zvíře by měl mít váš budoucí uchazeč? Všechna očekávání napište sem. Opět v odrážkách. Neposkytujte své telefonní číslo ani e-mail!',
  cvErrorTitle: 'Ups, někde se stala chybička.',
  requestNotSendTryAgain: 'Tvá žádost nebyla odeslána. Zkus to prosím znovu.',
  requestSuccessfullySent: 'Žádost úspěšně odeslána!',
  cvSuccessDescription:
    'Firmy mohou mít výběrové řízení otevřeno i několik týdnů. Prosíme tě o trpělivost při čekání na odpověď.',
  problemHasOccured: 'Nastal problém!',
  editErrorDescription:
    'Během ukládání vašich změn nastal problém. Prosím, zkuste úpravu ještě jednou. V případě přetrvávajících problémů kontaktujte podporu.',
  editNotAllowedDescription:
    'Na úpravu této nabídky nemáte dostatečná práva. Prosím ověřte vaše přihlášení. V případě přetrvávajících problémů prosím kontaktujte podporu na',
  goodJobTitle: 'Dobrá práce!',
  editSuccessDescription:
    'Vaše nabídka byla úspěšně upravena. Úpravu nabídky můžete provést kdykoli, před i po zveřejnění nabídky. URL adresu pro úpravu vaší nabídky naleznete v potvrzovacím e-mailu o schválení nabídky.',
  republishUnknownTitle: 'Hm, narazili jsme na problém!',
  republishUnknownDescription:
    'Vypadá to, že duplikování nebude možné. Tento link je zřejmě propojen s jiným firemním profilem. Zkuste to znovu nebo pokračujte přihlášením pod jiným účtem.',
  similarJobs: 'Podobné joby',
  lookIntoUnpublishedOffer: 'Náhled nepublikované nabídky',
  tenderHasBeenClosed: 'Výběrové řízení bylo uzavřeno',
  offerHasExpired: 'Platnost této nabídky skončila',
  offerIsPaused: 'Nabídka je pozastavena',
  higherSalaryAttractMoreCandidates: 'Vyšší mzda přiláká více uchazečů.',
  salaryNoteText:
    'Na základě našich statistik je průměrná odměna na této pozici od {{value}} € (full-time). Přihoďte trošku více',
  upgradeJobUpsellHeading: 'Aktuálně máme {{count}} nabídek pro {{categoryName}}',
  upgradeJobUpsellText:
    'Změňte typ nabídky na PREMIUM. Nabídka bude viditelnější a bude mít o +50 % větší dosah.',
  closeJob: 'Uzavřít job',
  closeJobDescription:
    'Vaše nabídka se uzavře a nebude se zobrazovat na webu. Všechny informace k ní budete mít stále dostupné ve vašem firemním profilu.',
  pauseJob: 'Pozastavit job',
  pauseJobDescription:
    'Vaše nabídka se dočasně nebude zobrazovat na webu. Všechny informace k ní budete mít stále dostupné ve vašem firemním profilu.',
  statusText: {
    waiting: '🕔 Čeká na schválení',
    expired: '⚠️ Expirována',
    unpublished: '🔕 Pozastaveno',
    banned: '⛔️ Zamítnuta',
    closed: '🔒 Uzavřená',
    online: 'Online',
    draft: '✏️ Rozpracovaná',
  },
  shareOffer: 'Sdílet nabídku',
  candidateHasAddedNote: 'Uchazeč má přidanou poznámku',
  candidateUpsellText:
    'Psst. Podívejte se na přetlak freelancerů. Najdete tam {{count}} lidí, kteří na vás čekají.',
  approvalEmailSentToCandidate: 'Uchazeči byl odeslán e-mail o přijetí.',
  approvalEmailSentToCandidateDate: 'Uchazeči byl odeslán e-mail o přijetí. ({{date}})',
  rejectionEmailSentToCandidate: 'Uchazeči byl odeslán e-mail o zamítnutí.',
  rejectionEmailSentToCandidateDate:
    'Uchazeči byl odeslán e-mail o zamítnutí. ({{date}})',
  doNotBeWorryClickButton:
    'Nebojte se, stisknutím tlačítka se uchazeč zatím nic nedozví.',
  cooperationFormShort: 'Forma',
  todayOnly: 'Už jen dnes!',
  daysLeft: {
    one: 'Už jen {{count}} den',
    other: 'Ještě {{count}} dny',
    many: 'Ještě {{count}} dnů',
  },
  jobLevelName: {
    premium: 'Premium',
    exclusive: 'Exkluziv',
    standard: 'Standard',
  },
  candidateDefaultResponseMessage: `Ahoj {{candidateName}}.[[nl]][[nl]]Děkujeme za zájem pracovat na pozici {{jobPosition}}. {{defaultMessage}}[[nl]][[nl]]S pozdravem, Tým {{teamName}}`,
  jobFormEdit: 'Upravit',
  jobFormPreview: 'Náhled',
  currency: 'Měna',
  upsellHowToJobPost: {
    strong: 'Chcete napsat nabídku, která zaujme?',
    regular: 'Připravili jsme jednoduchý návod jak na to.',
  },
  refreshModal: {
    title: `Vaše nabídka je na {{num}}. pozici!`,
    titleAlt: `Vaše nabídka je na 99+ pozici!`,
    description: `Pracovní nabídka <strong> {{jobName}} </strong> byla obnovena. <br/>Můžete ji topovat nebo extra přesdílet a tak ji dostat k více novým zájemcům!`,
  },
  removeDraftModal: {
    title: 'Vymazat nabídku?',
    description:
      'Stisknutím tlačítka bude vaše rozpracovaná nabídka smazána. Tento krok již nebude možné vrátit zpět.',
    submit: 'Ano, vymazat',
    decline: 'Ne, nechci smazat',
  },
  lowSalaryLimit: 'Spodní hranice',
  highSalaryLimit: 'Horní hranice',
  promocodeActiveJobsInfo: 'Balíček nabídek aktivní. Zůstává {{count}} nabídek',
  promocodeActiveDaysjInfo: {
    one: 'Balíček nabídek aktivní. Zůstává {{count}} den.',
    other: 'Balíček nabídek aktivní. Zůstávají {{count}} dny.',
    many: 'Balíček nabídek aktivní. Zůstává {{count}} dní.',
  },
  addonsDiscount: 'Sleva 25 % na všechno',
  loginBanner: {
    title: 'Přidávejte joby',
    free: 'zdarma',
    freeDesc: 'zdarma',
    desc: 'Kupte si výhodné balíčky jobů',
    or: 'nebo',
    cta: 'Zobrazit výhodné balíčky',
  },
  upsell: {
    title: 'Přidávejte nabídky ZDARMA! Cože???',
    description: 'Kupte výhodné balíčky pracovních nabídek 4+1 nebo 7+3 zdarma.',
    cta: 'Podívat se na ceník',
  },
  pricingBanner: {
    text: 'Kupte výhodné balíčky pracovních nabídek 4+1 nebo 7+3 zdarma 👀',
  },
  showPreview: 'Zobrazit náhled',
}
