const actions = {
  submit: 'Potvrdiť',
  send: 'Odoslať',
  pay: 'Dokončiť a zaplatiť',
  edit: 'Upraviť',
  open: 'Otvoriť',
  top: 'Topovať',
  publish: 'Publikovať',
  duplicate: 'Duplikovať',
  save: 'Uložiť',
  saveAndClose: 'Uložiť a zatvoriť',
  loadMore: 'Načítať viac',
  hide: 'Skryť',
  show: 'Zobraziť',
  select: 'Vybrať',
  selectOneJob: 'Vybrať 1 ponuku',
  conclude: 'Uzatvoriť',
  pause: 'Pozastaviť',
  login: 'Prihlásiť',
  logout: 'Odhlásiť',
  add: 'Pridať',
  close: 'Zavrieť',
  cancel: 'Zrušiť',
  showMore: 'Zobraziť viac',
  signUp: 'Zaregistrovať',
  finishLater: 'Dokončiť neskôr',
  delete: 'Vymazať',
}

const error = {
  invalid: 'Neplatný',
  wrongFormat: 'Chybný formát',
  selectAtLeastOneOption: 'Vyberte aspoň 1 možnosť',
  invalidURL: 'Neplatný formát URL adresy',
  invalidData: 'Neplatné údaje',
  wrongEmailOrPassword: 'Ups! Bol zadaný nesprávny e-mail alebo heslo.',
  emailExists: 'Zadaný e-mail je už registrovaný.',
  invalidFormat: 'Nesprávny formát',
  invalidEmail: 'Neplatný email',
  invalidPhone: 'Neplatné číslo',
  errorWhileLogingIn: 'Pri prihlasovaní nastala chyba',
  somethingWenWrongTrySendFormAgain:
    'Ups! Niečo sa pokazilo. Prosím skúste formulár odoslať znovu.',
  sendingError: 'Ups! Pri odosielaní nastala chyba.',
  errorDuringSaving: 'Pri ukladaní zmien nastala chyba',
  errorDuringProcessingTryAgain:
    'Pri spracovaní nastala chyba. Prosím skúste svoju akciu znovu.',
  errorDuringLoadingTryAgain:
    'Pri načítavaní nastala chyba. Skúste znovu načítať stránku.',
  unknownErrorTrySendAgain: 'Neznáma chyba, prosím skúste odoslať znovu.',
  errorCheckAllRequiredField: 'Chyba! Prosím skontrolujte všetky povinné polia.',
  fillAllRequiredFormFields: 'Ups! Vyplňte všetky povinné polia formuláru.',
  registrationError: 'Ups! Pri registrácii nastala chyba.',
  savingError: 'Ups! Pri ukladaní nastala chyba.',
  addingJobError: 'Ups! Pri pridávaní jobu nastala chyba.',
  unknownErrorTryAgain: 'Ups! Neznáma chyba. Prosím skúste znovu.',
  uploadFileError: 'Vyskytla sa chyba pri uploade súboru.',
  maxFileSizeExceeded: 'Bola prekročená maximálna veľkosť súboru.',
  minFileDimensionExceeded: 'Obrázok má príliš malý rozmer.',
  insertPdfFile: 'Prosím vložte súbor vo formáte .pdf.',
  insertDocument: 'Prosím vložte súbor vo formáte .pdf., .doc alebo .docx.',
  selectedFileIsBroken: 'Vybraný súbor je poškodený. Skontroluj ešte raz svoj výber.',
  somethingWentWrongTryAgainOrContactUs:
    'Ups 😳 Niečo sa pokazilo. Opakujte požiadavku alebo nás prosím kontaktujte.',
  somethingWentWrongPleaseRefresh: 'Ups! Niečo sa pokazilo. Prosím skúste refresh.',
  pleaseRefresh: 'Ups! Prosím skúste refresh.',
}

const tooltip = {
  countryInputDisabled: 'Pre zmenu krajiny prosím najskôr odstráňte mesto.',
  photoUploadTip:
    'Tip: Môžete použiť rovnaké logo ako máte nahraté na facebook stránke vašej firmy. Stačí si ho stiahnuť a potom nahrať sem kliknutím na tlačidlo Vybrať.',
  photoUploadFormat: `
    Vkladajte <strong>.jpg, .png, .gif</strong> súbor. Maximálna veľkost
    {{maxSize}}Mb. <br />
    Odporúčané rozmery {{dimension}}x{{dimension}}px.`,
  photoFreelancerUploadFormat: `
    Vkladajte <strong>.jpg, .png, .gif</strong> súbor. Maximálna veľkost
    {{maxSize}}Mb. <br />
    Odporúčané rozmery {{dimension}}x{{dimension}}px.`,
  personalDataInfo:
    'Tento údaj nebude dostupný nikde na webe. Slúži pre potreby spracovania osobných údajov.',
  gdprDataInfo: 'Tento údaj nebude nikde zverejnený, slúži len na účely GDPR.',
  textareaFormatInfo: 'Pre klikatelnú URL používajte tvar https://pretlak.com.',
  telephoneInput:
    'Slúži len pre nás v prípade riešenia nejakého problému. Nebude sa nikde verejne zobrazovať.',
  salary:
    'Uveď nám prosím to, ako si ceníš svoj čas. Chceme byť transparentní a formovať trh tým správnym smerom.',
  certification:
    'Ak máš za sebou niečo, čo stojí za zmienku, spomeň to teraz alebo nikdy. Napr. Kurz varenia 😉',
  exportToCsvForPackages:
    'Export do CSV je možný len pri ponukách typu Exkluzív a Prémium',
  hrUrl: 'Slúži na prepojenie s ATS / Recruitment systémom',
  packageExpireDate:
    'Platnosť balíka s 5 ponukami je časovo neobmedzená. Platíte za 4 ponuky (jedna je zadarmo).',
  mediumPackageExpireDate:
    'Platnosť balíka s 10 ponukami je časovo neobmedzená. Platíte za 7 ponúk (3 sú zadarmo).',
}

const placeholder = {
  yourEmail: 'Tvoj e-mail',
  minimumChars: 'Minimálne {{count}} znakov',
  city: 'Začnite písať',
  cityShort: 'Vyberte mesto',
  companyName: 'Začnite písať názov firmy pre zobrazenie výsledkov...',
  aboutCompany: 'Základné informácie o firme, hodnoty, história, úspechy, veď viete...',
  fullName: 'Zadajte celé meno',
  note: 'Sem napíšte poznámku',
  salaryNote:
    'Ponúkate bonus, ESOP, iné finančné benefity, alebo je súčasťou platu variabilná zložka?',
  aboutMe:
    'Poď zaujať potenciálneho klienta – predaj sa, uleť si, spomeň čo robíš, čo nerobíš, s kým si už spolupracoval alebo aká je tvoja obľúbená značka tenisiek,... Nezadávaj do profilu svoje telefónne číslo ani e-mail.',
  education:
    'Nepreceňujeme ho ani nepodceňujeme. Necháme na teba, ako s touto položkou naložíš',
  portfolio:
    'Teraz máš priestor podrobnejšie opísať svoje projekty, na ktoré si hrdý alebo aj nie. Pridaj referenčné linky, emojis, veď vieš...',
  countrySelect: 'Napr. Slovensko',
  candidateResponseModalText: 'Vaša správa ktorú odošleme uchádzačovi e-mailom',
  invoiceNote: 'Napr. číslo objednávky',
  hrUrl: 'Napr. https://pretlak.com',
  introductionText:
    'Vyšlite úvodný signál! Môžete sem zhrnúť všeobecné informácie o vás, o pozícii či ideálnom kolegovi. Alebo napíšete nejaký vtip?',
}

const label = {
  workMail: 'Pracovný e-mail',
  requirements: 'Požiadavky',
  contactMail: 'Kontaktný email',
  nameAndSurname: 'Meno a priezvisko',
  residenceCity: 'Mesto trvalého bydliska',
  birthYear: 'Rok narodenia',
  telephoneNumber: 'Telefónne číslo',
  invoiceNote: 'Poznámka na faktúru',
  hrUrl: 'Odkaz na ATS systém',
  introductionText: 'Na privítanie',
}

const search = {
  placeholderJobs: 'Vyhľadaj job, firmu ...',
  placeholderFreelancers: 'Vyhľadaj freelancerov ...',
  placeholderCompanies: 'Vyhľadaj firmy ...',
  lastSearched: 'Naposledy hľadané',
  lastSearchedMobile: 'Naposledy',
}

const shortcuts = {
  companyId: 'IČO',
  vatID: 'IČ DPH (nie pre CZ firmy)',
  vatNumber: 'DIČ',
  postCode: 'PSČ',
  vat: 'DPH',
  forExample: 'Napr.',
}

const footer = {
  text: 'Potrebujete poradiť?',
}

module.exports = {
  actions,
  error,
  tooltip,
  placeholder,
  label,
  search,
  shortcuts,
  footer,
  availability: 'Dostupnosť',
  new: 'Novinka',
  addVideoPlaceholder: 'Vložte link na vaše video',
  password: 'Heslo',
  more: 'Viac',
  free: 'Zadarmo',
  requiredField: 'Povinný údaj',
  wantReceiveEmailWeeklyOffers: 'Chcem dostávať každý týždeň podobné ponuky e-mailom',
  somethingAboutMe: 'Tvoja šanca vyniknúť',
  basicInfoAboutMe:
    'Skúsenosti, motivácia, báseň či dôležitý link, vynikni po svojom. Firmy to fakticky čítajú a dokonca sa niekedy podľa toho rozhodujú!',
  noOfferFound: 'Žiadna ponuka nebola nájdená.',
  noCompanyFound: 'Žiadna firma nebola nájdená.',
  noCompanyJobs: 'Firma nemá žiadne otvorené joby.',
  loadMore: 'Načítať viac',
  loginTitle: 'Prihlásenie',
  loginMyself: 'Prihlásiť sa',
  loginYourself: 'Prihláste sa',
  loginYourselfLowercase: 'prihláste sa',
  great: 'Paráda!',
  forgetYourPassword: 'Zabudli ste heslo?',
  forgottenPasswordDescription:
    'Nevadí, to sa stáva. Zadajte váš e-mail a my vám pošleme návod ako si ho obnoviť',
  addProfile: 'Pridať profil',
  noRegistrationYet: 'Nemáte ešte svoje prihlasovacie údaje?',
  noRegistrationYetFreelancer: 'Nemáš ešte vytvorený profil?',
  registerYourself: 'Zaregistrujte sa',
  registerYourselfFreelancer: 'Zaregistruj sa',
  moreInfo: 'Viac informácií',
  moreInfoShort: 'Viac info',
  iAmInterested: 'Mám záujem',
  registerFreelancerProfile: 'Zaregistrujte si profil freelancera',
  form: {
    selectOption: 'Vyberte možnosť',
    typeNewPassword: 'Zadajte nové heslo',
    typeNewPasswordAgain: 'Znovu zadajte nové heslo',
    saveNewPassword: 'Uložiť nové heslo',
    typeNewEmail: 'Zadaj nový email',
    saveNewEmail: 'Uložiť nový email',
  },
  toPageTop: 'Navrch stránky',
  aboutUs: 'O nás',
  continue: 'Pokračovať',
  talentBox: {
    text: 'Stovka uchádzačov ihneď k dispozícii. Výberový proces tak môžete začať okamžite. Prístup k celej databáze životopisov na vyžiadanie.',
    freshCVsDatabase: `Čerstvá
      <br />
      Databáza
      <br />
      Životopisov`,
    freelancerCategoryWithCount: {
      marketers: '{{count}} marketérov',
      designers: '{{count}} dizajnérov',
      developers: '{{count}} developerov',
      creatives: '{{count}} kreatívcov',
      nonTech: '{{count}} non-tech',
    },
    price: 'Cena na vyžiadanie',
  },
  weRecommend: 'ODPORÚČAME',
  availableNow: 'Dostupné ihneď',
  favorite: 'Obľúbené',
  category: 'kategória',
  jobOffers: 'Pracovné ponuky',
  freelancers: 'Freelanceri',
  advantageousOffers: 'Výhodné ponuky',
  databaseOfCVs: 'Databáza životopisov',
  advantageousPackages: 'Výhodné balíky',
  publicProfile: 'Verejný profil',
  companySubscriptionEdit: 'Upraviť predplatné',
  companyInfo: 'Firemné údaje',
  addMissingCompanyInfo: 'Doplňte chýbajúce údaje o firme v časti',
  emailNotificationsSettings: 'Nastavenie e-mail notifikácií',
  iWantCandidateMailNotifications:
    'Chcem dostávať e-mail s upozornením na nového uchádzača',
  iWantOffersMailNotifications: 'Chcem dostávať informácie o stave mojej ponuky.',
  iWantInoAboutProductsStatus: 'Chcem dostávať novinky a tipy na zlepšenie profilu.',
  iWantAllMailNotifications: 'Chcem dostávať všetky e-maily.',
  telephoneNumberPrefix: '+421',
  invoices: 'Faktúry',
  noInvoicesYet: 'Zatiaľ pre Vás nemáme vystavené žiadne faktúry.',
  publishToSocialNetwork: 'Publikovať na sociálne siete',
  cardPayment: 'Platba kartou',
  publishOfferImmediately: 'Zverejnenie ponuky ihneď',
  publishOfferAfterPayment: 'Zverejnenie ponuky po úhrade',
  willDoImmediately: 'Vykonáme ihneď',
  viewImmediately: 'Zobrazenie ihneď',
  invoiceBankTransfer: 'Faktúra - prevodom na účet',
  companiesThatTrustedUsTitle: 'Firmy, ktoré nám prejavili dôveru a hľadali ľudí u nás',
  summary: 'Spolu',
  summaryWithVAT: 'Spolu s DPH',
  summaryWithoutVAT: 'Spolu bez DPH',
  iAmOfferingJob: 'Ponúkam prácu',
  iAmLookingForJob: 'Hľadám prácu',
  tellUsWhyAreYouHere: 'Prezraď nám, prečo si sem prišiel?',
  paymentGateway: 'Platobná brána',
  note: 'Poznámka',
  news: 'Novinky',
  queryModal: {
    text: 'Informácie o uchádzačoch sú u nás kvôli nariadeniu GDPR dostupné 12 mesiacov od reakcie na ponuku. Potom sa automaticky vymažú.',
    feedbackText: 'Napíšte nám vašu skúsenosť a spätnú väzbu. Ďakujeme.',
  },
  successPaymentModal: {
    freelancerAddonSuccess: 'Vybranú službu sprocesujeme maximálne do 24 hodín.',
    addonColorText: 'Zvýraznenie" pracovnej ponuky vlastnou farbou',
    wasSuccessFullyDone: 'bolo úspešne vykonané.',
    extraShareNote: 'Extra zdieľanie vykonanáme do 24 hodín.',
    topForDays: 'Topovanie na {{days}} dní',
    freelanceContent: {
      heading: 'Freelanceri sú odomknutí',
      description:
        'Každý týždeň dostanete e-mailom nových freelancerov. Vyberte kategórie.',
    },
    freelanceAddonContent: {
      top: 'topovanie',
      customColor: 'zvýraznenie profilu vlastnou farbou',
      socialShare: 'extra prezdieľanie vykonanáme do 24 hodín',
    },
    addonContent: {
      weSentInvoiceToPay: 'Faktúru na úhradu sme vám odoslali.',
      shareUpToThreeDays: 'Zdieľanie prebehne maximálne do 3 dní.',
    },
    jobContent: {
      creditCardText:
        'Ďakujeme! Do nasledujúceho pracovného dňa ponuku skontrolujeme, zverejníme na webe a prezdieľame na sociálnych sieťach.',
      promoCodeText:
        'Do nasledujúceho pracovného dňa ponuku skontrolujeme, zverejníme na webe a prezdieľame na sociálnych sieťach.',
      bankTransferText: `Pracovnú ponuku skontrolujeme, zverejníme na webe a prezdieľame na
        sociálnych sieťach <strong>až po úhrade faktúry</strong>. Prosíme o
        úhradu faktúry, ktorú nájdete vo svojom e-maili.`,
      cvDatabaseWillBeSend:
        'Databáza životopisov Vám bude zaslaná e-mailom po sprocesovaní.',
    },
    jobWithPackageContent: {
      creditCardText:
        'Zakúpenie balíka ponúk a pridanie ponuky bolo úspešné. Do nasledujúceho pracovného dňa ponuku skontrolujeme, zverejníme na webe a prezdieľame na sociálnych sieťach.',
      bankTransferText: `Zakúpenie balíka ponúk a pridanie ponuky bolo úspešné. Ponuku
        skontrolujeme, zverejníme na webe a prezdieľame na sociálnych sieťach 
        <strong>až po úhrade faktúry</strong>. Prosíme o úhradu faktúry, ktorú
        nájdete vo svojom e-maili.`,
      talentBoxWillBeSend: 'Talentbox Vám bude zaslaný e-mailom po sprocesovaní.',
      activePromoCode: 'Aktívny výhodný balík nájdete vo firemnom profile. Zobraziť.',
      successPayment: 'Platba prebehla úspešne!',
    },
    packageContent: {
      creditCardText:
        'Ďakujeme! Zakúpenie balíka ponúk bolo úspešné. Môžete si ho zobraziť v časti výhodné balíky. Pri pridávaní pracovnej ponuky budete mať promokód zo zakúpeného balíka automaticky vyplnený.',
      bankTransferText:
        'Faktúru na úhradu sme vám odoslali. Zakúpenie balíka ponúk bolo úspešné. Môžete si ho zobraziť v časti výhodné balíky. Pri pridávaní pracovnej ponuky budete mať promokód zo zakúpeného balíka automaticky vyplnený.',
    },
  },
  additionalCompanyModal: {
    tellUs: 'Ešte nám prezraďte',
    whatCategoryIsYourCompany: 'Do ktorej kategórie patrí vaša spoločnosť?',
    whereDidYouHearAboutUs: 'Odkiaľ ste sa o nás dozvedeli?',
  },
  copyLink: 'Skopírovať link',
  shareWithEmail: 'Zdieľať e-mailom',
  checkWhatToDoForHigherOfferReach:
    'Zistite, čo robiť, aby mala vaša ponuka, čo najväčší dosah! Kliknite sem',
  profileWasRemoved: 'Profil bol vymazaný!',
  backToFreelancers: 'Späť na freelancerov',
  certificationTitle: 'Ocenenia, certifikáty',
  portfolio: 'Portfólio',
  otherAttachment: 'Iná príloha',
  portfolioUploadError:
    'Portfólio / Iná príloha sa nepodarilo nahrať. Prosím skúste to znova.',
  cvUploadError: 'Životopis sa nepodarilo nahrať. Prosím skúste to znova.',
  education: 'Vzdelanie',
  backToIntro: 'Späť na úvod',
  almostDone: 'Takmer hotovo!',
  recoverPasswordInstructionSentToMailCompany:
    'Práve sme vám na zadaný e-mail poslali inštrukcie, ako si obnoviť svoje heslo. Veríme, že čoskoro budete opäť s nami.',
  recoverPasswordInstructionSentToMail:
    'Práve sme ti na zadaný e-mail poslali inštrukcie, ako si obnoviť svoje heslo. Veríme, že čoskoro budeš opäť s nami.',
  yourPasswordSuccessfullyRestored:
    'Vaše heslo je úspešne obnovené. Pokračujte prihlásenim a užívajte Pretlak.',
  yourPasswordSuccessfullyRestoredFreelancer:
    'Tvoje heslo je úspešne obnovené. Pokračuj prihlásenim a užívaj Pretlak.',
  passwordReset: 'Reset hesla',
  hurayYouCanAddYourNewPassword:
    'Hurá. Teraz môžete zadať vaše nové heslo a pokračovať prihlásením.',
  newPassword: 'Nové heslo',
  checkPassword: 'Skontrolujte heslo',
  submitNewPassword: 'Potvrdenie nového hesla',
  viewAll: 'Zobraziť všetky',
  selectCandidate: 'Vybrať uchádzača',
  selectAndSendMail: 'Vybrať a odoslať e-mail',
  selectWithoutSendMail: 'Vybrať bez odoslania e-mailu',
  candidateApprovalModalMessage:
    'Vyhovuješ našim požiadavkám a to znamená, že ťa budeme čoskoro kontaktovať.',
  notes: 'Poznámky',
  typeNoteToCandidate: 'Sem napíšte svoje poznámky k uchádzačovi...',
  placeForYourPrivateNotes: 'Priestor pre vaše poznámky, ktoré uvidíte len vy.',
  rejectCandidate: 'Zamietnuť uchádzača',
  rejectAndSendMail: 'Zamietnuť a odoslať e-mail',
  rejectWithoutSendMail: 'Zamietnuť bez odoslania e-mailu',
  rejectCandidateModalMessage:
    'Bohužiaľ, nevyhovuješ našim požiadavkám a do ďalšieho kola nepostupuješ. Prajeme veľa úspechov v ďalšom hľadaní.',
  promoCodeCopied: 'Promo kód bol skopírovaný.',
  activePackages: 'Aktívne balíky',
  active: 'Aktívne',
  used: 'Využitý',
  remains: 'Zostáva',
  expires: 'Expiruje',
  renewal: 'Obnovenie',
  promoCode: 'Promokód',
  promoCodeValidUntil: 'Platnosť promokódu',
  promoCodeName: 'Názov promokódu',
  copyPromoCode: 'Skopírovať promokód',
  packagesOffer: 'Ponuka balíkov',
  displayMode: 'Režim zobrazenia',
  accountSettings: 'Nastavenie účtu',
  validity: 'Platnosť',
  countOfOffers: 'Počet ponúk',
  back: 'Späť',
  loading: 'Načítavam',
  salary: 'Plat',
  city: 'Mesto',
  country: 'Krajina',
  positionName: 'Názov pozície',
  companyName: 'Názov firmy',
  pricing: 'Cenník',
  magazine: 'Magazín',
  statistics: 'Štatistiky',
  buyItemAfterSubmitText:
    'Po potvrdení vám vystavíme faktúru v hodnote {{price}}€ + DPH a vytvoríme balík s aktívnym promokódom.',
  buyPackageModal: {
    success: {
      title: 'Ďakujeme za zakúpenie balíka ponúk. Faktúru na úhradu sme vám odoslali.',
      promocodeText: 'Promokód "{{code}}" je aktívny. Zobraziť výhodný balík',
    },
    content: {
      contactText: 'Potrebujete viac info? Napíšte na',
      callUs: 'zavolajte',
    },
  },
  invoice: {
    name: 'Faktúra',
    view: 'Zobraziť faktúru',
    price: 'Suma',
    created: 'Vytvorená',
    number: 'Číslo faktúry',
    paid: 'Uhradená',
    unpaid: 'Neuhradená',
    afterExpiration: {
      one: 'Po splatnosti {{count}} deň',
      other: 'Po splatnosti {{count}} dni',
      many: 'Po splatnosti {{count}} dní',
    },
  },
  hideProfile: 'Skryť profil',
  publishProfile: 'Publikovať profil',
  editProfile: 'Upraviť profil',
  showPublicProfile: 'Zobraziť verejný profil',
  deleteProfile: 'Vymazať profil',
  submitDeleteProfileText:
    'Stlačením tlačidla nižšie bude tvoj profil vymazaný. Na veky vekov. Naozaj to chceš urobiť?',
  profile: 'Profil',
  myProfile: 'Môj profil',
  proSubscription: 'Pro členstvo',
  waitingForApproval: 'Čaká na schválenie',
  findOutMore: 'Zistiť viac',
  backToJobOffers: 'Späť na pracovné ponuky',
  countOfCanditates: 'Počet uchádzačov',
  added: 'Pridané',
  updated: 'Aktualizované',
  sharing: 'Zdieľanie',
  topping: 'Topovanie',
  extraSharing: 'Extra zdieľanie',
  topOffer: 'Topovať ponuku',
  topOfferDescription: 'Môžete vybrať viac možností',
  jobTopOfferDescription:
    'Môžete vybrať viacero možností. Každá topovaná ponuka bude zaradená aj do PPC kampaní.',
  noReactionsToPostYet: 'Zatiaľ nemáte žiadne reakcie na váš inzerát.',
  noReactionsHr:
    'Vaša ponuka používa vlastný Odkaz na ATS systém / recruitment systém, v ktorom nájdete aj životopisy uchádzačov k tejto pracovnej ponuke.',
  squeezTheMostFromOffer:
    'Vyžmýkajte z vašej ponuky maximum a doprajte jej niečo navyše.',
  candidate: 'Uchádzač',
  date: 'Dátum',
  countExpiredCandidates: '{{count}} expirovaných uchádzačov',
  infoAboutCandidatesWillRemovedAfterThreeMonths:
    'Informácie o uchádzačoch sú u nás kvôli nariadeniu GDPR dostupné <strong>12 mesiacov</strong> od reakcie na ponuku. Potom sa automaticky vymažú.',
  colorizeOffer: 'Zafarbenie ponuky',
  package: 'Balík',
  discount: 'Zľava',
  offerPrice: 'Cena ponuky',
  buyPackageAndSaveMoney: 'Zakúpte si balík ponúk a ušetrite!',
  cvModal: {
    vipListTitle: 'Staň sa VIP uchádzačom',
    vipListDescription:
      'Vyplň tieto tri údaje a automaticky ťa zaradíme do našej VIP databázy uchádzačov, ktorú si firmy pravidelne prezerajú. Je to zadarmo!',
    gdpr: 'Vaše osobné údaje, získané prostredníctvom životopisov, môžu byť zdieľané s ďalšími zamestnávateľmi hľadajúcimi vhodných kandidátov na pracovné pozície na základe oprávneného záujmu, najdlhšie 12 mesiacov. V prípade, že si neprajete, aby sme vaše osobné údaje naďalej spracovávali, máte právo kedykoľvek požiadať o ich vymazanie.',
  },
  duplicateUpsell: {
    title: 'Duplikujte ponuku!',
    text: 'Šetrite čas. Nepíšte inzerát nanovo, zduplikujte ho.',
    cta: 'Chcem duplikovať',
  },
  buyAddonModal: {
    contentTooltip:
      'Ponuka bude navrchu hlavnej stránky po dobu, pokiaľ ju ďaľšia nenahradí. Dátum pridania ponuky sa aktualizuje.',
    extraShareText: 'Extra prezdieľať na sociálnych sieťach',
    shareTooltip:
      'Ponuku znovu prezdielame na Facebooku, Instagrame, LinkedIne, kde máme viac ako 28 000 sledovateľov. Zobrazíme ju v týždennom Newsletteri, ktorý pravidelne posielame viac ako 7 000 uchádzačom.',
    thankyou: {
      desc1:
        'Topovanie na 7 dní, Extra Share a zafarbenie ponuky sú aktivované. Zdieľanie prebehne maximálne do 3 dní. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc2:
        'Topovanie na 14 dní, Extra Share a zafarbenie ponuky sú aktivované. Zdieľanie prebehne maximálne do 3 dní. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc3:
        'Extra Share a zafarbenie ponuky sú aktivované. Zdieľanie prebehne maximálne do 3 dní. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc4:
        'Topovanie na 7 dní a Extra Share sú aktivované. Zdieľanie prebehne maximálne do 3 dní. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc5:
        'Topovanie na 14 dní a Extra Share sú aktivované. Zdieľanie prebehne maximálne do 3 dní. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc6: 'Topovanie na 7 dní je aktívne. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc7: 'Topovanie na 14 dní je aktívne. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc8:
        'Extra Share je aktivovaný. Zdieľanie prebehne maximálne do 3 dní. Vo vašom mailboxe nájdete faktúru. 🙌',
      desc9: 'Ponuka je zafarbená. Vo vašom mailboxe nájdete faktúru. 🙌',
    },
  },
  multiplationViews: '{{value}} VIAC VIDENÍ',
  multiplationPercentageViews: '+{{value}}% VIDENÍ',
  highlightOffer: 'Zvýrazniť ponuku',
  colorizeOfferWithValue: 'Zvýrazniť ponuku - {{value}}',
  thankYou: 'Ďakujeme',
  freelanceAddonThankYouTitle: 'Platba prebehla úspešne!',
  addJob: 'Pridať job',
  editJob: 'Upraviť job',
  duplicateJob: 'Duplikovať job',
  countCurrentJobOffers: {
    one: '{{count}} aktuálna ponuka',
    other: '{{count}} aktuálne ponuky',
    many: '{{count}} aktuálnych ponúk',
  },
  company: 'Firma',
  companies: 'Firmy',
  companiesCount: {
    one: '{{count}} firma',
    other: '{{count}} firmy',
    many: '{{count}} firiem',
  },
  jobs: 'Joby',
  offersCount: {
    one: '{{count}} ponuka',
    other: '{{count}} ponuky',
    many: '{{count}} ponúk',
  },
  jobsCount: {
    one: '{{count}} job',
    other: '{{count}} joby',
    many: '{{count}} jobov',
  },
  freelancersCount: {
    one: '{{count}} freelancer',
    other: '{{count}} freelanceri',
    many: '{{count}} freelancerov',
  },
  notFoundTitle: 'Narazil si na stratené miesto.',
  selectPaymentMethod: 'Vyberte spôsob platby',
  maximumMbSize: 'Maximálna veľkost {{size}}',
  file: 'súbor',
  insert: 'Vkladajte',
  paymentDue: 'Splatnosť',
  buySuitablePackage: 'Kúpiť výhodný balík',
  iAgreeWith: {
    start1: 'Súhlasím so',
    subject1: 'spracovaním osobných údajov',
    purpose1:
      'na účely dostávania exkluzívných akciových ponúk a iných noviniek z Pretlaku.',
    start2: 'Súhlasím s',
    subject2: 'podmienkami používania',
    purpose2: 'za účelom vytvorenia osobného profilu na našom webe.',
    purpose3: 'za účelom pomoci pri hľadaní podobnej pracovnej pozície.',
    end1: 'webu Prelak',
  },
  aboutCompany: 'O firme',
  companyHasNoFreePositions: 'Táto spoločnosť momentálne nemá žiadne voľné pozície.',
  buySummaryTooltip:
    'Databáza životopisov ihneď k dispozícii. Výberový proces tak môžete začať okamžite.',
  permanentEmployment: 'TPP Trvalý pracovný pomer',
  invoicing: 'Fakturácia (SRO, Živnosť, ...)',
  paymentInfo: 'Platobné údaje',
  paymentEmail: 'Fakturačný e-mail',
  weOffer: 'Ponúkame',
  contact: 'Kontakt',
  viewsCount: 'Počet zobrazení',
  clickCount: 'Počet preklikov',
  type: 'Typ',
  dateAdded: 'Pridané',
  dateUpdated: 'Aktualizované',
  dateSignup: 'Registrovaný',
  action: 'Akcia',
  largeAmount: 'veľké množstvo',
  promocodeAvailableImmediately: 'Dostupný ihneď',
  freeCountLabel: '{{label}} zadarmo',
  offer: {
    default: 'ponuka',
    one: '{{count}} ponuka',
    other: '{{count}} ponuky',
    many: '{{count}} ponúk',
  },
  yourCompanyProfileState: 'stav vašeho firemního profilu',
  state: 'Stav',
  year: 'rok',
  price: 'cena',
  priceAsk: 'Na vyžiadanie',
  priceAskTitle: `Mám záujem o {{package}} nekonečný balík`,
  days: {
    one: '{{count}} deň',
    other: '{{count}} dni',
    many: '{{count}} dní',
  },
  companyLogo: 'Logo firmy',
  viewsWithValue: '{{value}} zobrazení',
  selectCategory: 'Vyberte kategóriu',
  selectSkill: 'Vyberte úroveň',
  selectAvailability: 'Vyber dostupnosť',
  changePositionNameNote:
    'Názov pozície je možné zmeniť do 10 dní od zverejnenia ponuky.',
  boostOnWeb: 'Topovať na webe na {{count}} dní',
  categories: 'Kategórie',
  countOfCandidates: 'Počet uchádzačov',
  offerHasBeenClosed: 'Ponuka bola uzatvorená',
  suit: 'Vyhovuje',
  doesNotSuit: 'Nevyhovuje',
  attachments: 'Prílohy',
  cv: 'Životopis',
  other: 'Iné',
  downloadCv: 'Stiahnuť životopis',
  downloadPortfolio: 'Stiahnuť portfólio',
  upsellTop: {
    title: 'Topovanie!',
    text: 'Získajte 3-krát viac zobrazení. Dostante ponuku k novým uchádzačom.',
    cta: 'Chcem topovať',
  },
  cookiesBanner: {
    heading: 'Kuk, Cookies!',
    text: `Vec sa má tak, že náš web ukladá do tvojho zariadenia súbory cookie. Dôvod na paniku? Čosi. Cookies používame len na to, aby sme si ťa pamätali a dali dokopy pár informácií o tom, akým spôsobom používaš náš web. Vďaka tomu, bude tvoj zážitok u nás ešte lepší. Tu nájdeš`,
    textMobile:
      'Náš web ukladá do tvojho mobilného zariadenia súbory cookies. Tie používame len na to, aby sme si ťa pamätali a dali dokopy pár informácií o tom, akým spôsobom používaš náš web. To všetko, pre tvoj lepší zážitok. Tu nájdeš',
    agree: 'Povoliť všetko',
    disagree: 'Zamietnuť',
    save: 'Uložiť výber',
    settings: 'Prispôsobiť',
    settingsHeading: 'Nastavenie cookies',
    moreInfo: 'viac informácií',
    lessInfo: 'A tu menej informácií',
    functional: 'Potrebné',
    required: 'Povinné',
    optional: 'Voliteľné',
    analytics: 'Analytické',
    ad: 'Reklamné',
    duration: 'Platnosť',
    description: 'Popis',
    processor: 'Spracovateľ',
    date: {
      year: 'rok',
      years: 'roky',
      month: 'mesiac',
      months: 'mesiace',
      days: 'dní',
      day: 'deň',
    },
    detail: {
      general:
        'Cookies používáme k analýze údajov o tebe, našom návštevníkovi, aby sme zlepšili tvoj zážitok a zobrazovali ti personalizovaný obsah, lebo každý chce obsah na mieru, no nie?',
      functional:
        'Súbory Cookie nám všetkým pomáhajú vytvárať použiteľné webové stránky tak, že umožňujú ich základné funkcie, ako je navigácia stránky a prístup k chráneným oblastiam webových stránok. V skratke povedané - bez Cookies by to nešlo, čiže ich nie je možné zakázať.',
      analytics:
        'Analytické súbory Cookies nám pomáhajú v tom, aby sme lepšie pochopili, ako s tebou komunikovať prostredníctvom zbierania informácií. Samozrejme anonymne.',
      ad: 'Používame ich na sledovanie tvojich preferencií, za účelom zacielenia reklamy, alebo zobrazovania marketingových a reklamných oznámení (aj na stránkach tretích strán), ktoré by ťa mohli ako nášho návštevníka zaujímať. Všetko v súlade s týmito preferenciami. Marketingové Cookies využívajú aj nástroje externých spoločností. Na ich použitie však potrebujeme tvoj súhlas. Ideš do toho?',
    },
    descriptions: {
      ccSettings: 'Slúži k uloženiu nastavení cookies.',
      session: 'Slúži ku správnemu prihláseniu použivateľa.',
      cv: 'Slúži na predvyplnenie údajov použivateľa pri odosielaní CV.',
      freelancer:
        'Slúži na predvyplnenie údajov použivateľa pri kontaktovaní freelancera.',
      visited: 'Slúži na označovanie videních uchádzačov v profile firmy.',
      gaBasic: 'ID používané k identifikácii používateľov.',
      ga24: 'ID slúži k identifikácii používateľov po dobu 24 hodín od poslednej aktivity.',
      gaAds:
        'Používa Google AdSense k experimentom s efektivitou reklamny na webových stránkach využívajúcich ich služby.',
      metaAds:
        'Používa spoločnosť Meta k poskytovaniu reklamných produktov, ako sú ponuky v reálnom čase od inzerentov tretích strán.',
    },
  },
  jobDescription: 'Náplň práce',
  salaryPerHour: 'Hodinovka',
  residence: 'Sídlo',
  from: 'od',
  bonus: {
    one: 'bonus',
    other: 'bonusov',
    many: 'bonusy',
  },
  ppcCampaign: 'PPC kampaň',
  onlineCampaign: 'PPC online kampaň',
  countOfCVs: 'Počet CV',
  iWantBoostPrice: 'Chcem topovať - {{price}} € + DPH',
  iWantBoostPriceWithoutVat: 'Chcem topovať - {{price}} €',
  iWantBoost: 'Chcem topovať',
  companyCategoryName: {
    AGENCY: 'Agentúry & štúdiá',
    MEDIA: 'Médiá',
    TECH: 'Tech & Inovácie',
    TELCO: 'Telco, financie, verejná správa',
    CULTURE: 'Kultúra & Neziskový sektor',
    OTHERS: 'Ostatné',
  },
  companySubcategoryName: {
    AUDIO_VIDEO: 'Audio & Video produkcia',
    WEB_DESIGN_DEVELOPMENT: 'Web dizajn & development',
    GRAPHICS_DESIGN: 'Grafický dizajn',
    SOCIAL_MEDIA_CONTENT: 'Social Media & Content',
    PR_COMMUNICATION: 'PR a komunikácia',
    EVENT: 'Event',
    PRINT_ADVERTISING: 'Tlač & výroba reklamy',
    MEDIA_AGENCY: 'Mediálna agentúra',
    HR: 'HR',
    FULLSERVICE: 'Fullservis',
    IT_PROJECTS_DEVELOPMENT: 'Vývoj IT projektov',
    TV: 'TV',
    RADIO: 'Rádio',
    PRINT: 'Print',
    PUBLISHING_HOUSE: 'Vydavateľstvo',
    INDOOR_OUTDOOR: 'Indoor / Outdoor',
    STARTUPS: 'Startupy',
    E_COMMERCE: 'E-commerce',
    SAAS: 'Saas',
    GAMING: 'Gaming',
    BANKING: 'Bankovníctvo',
    INSURANCE: 'Poisťovníctvo',
    TELECOMMUNICATIONS: 'Telekomunikácie',
    GOVERNMENT: 'Verejná správa',
    GASTRONOMY_HOSPITALITY: 'Gastro & Hotelierstvo',
    CULTURE: 'Kultúra',
    ART: 'Umenie',
    CIVIC_ASSOCIATION: 'Občianske združenie',
    NON_PROFIT_ORGANIZATIONS: 'Neziskové organizácie',
    EDUCATION: 'Vzdelávanie',
    CONSTRUCTIONS: 'Stavebnictvo a reality',
    ARCHITECTURE_INTERIOR_DESIGN: 'Architektúra a interiérový dizajn',
    RETAIL: 'Retail',
    OTHER_CATEGORY: 'Iná katégoria',
  },
  cvs: 'Životopisy',
  finished: 'Ukončené',
  notUsed: 'Nepoužité',
  noCountryFound: 'Krajina sa nenašla',
  with: 's',
  exportTo: 'Export do {{shortcut}}',
  from: 'od',
  activeViews: 'Aktívne zobrazenia',
  topBar: {
    companies: 'Objav pretlak freelancerov!',
    freelancers: 'Pridaj sa k {{count}} freelancerom aj ty!',
    freelancersWithoutNumber: 'Pridaj sa k freelancerom aj ty!',
    freelancersProStart: `Prejdi na `,
    freelancersProEnd: ` a získaj viac!`,
    merch: 'Máme merch, ktorý ste si všetci pýtali! Kúpiš',
    merchUnderline: 'TU TU TU',
    merchMobile: 'Máme merch!',
    freelancersOffer: 'Len teraz, zľava 25 % na ročné PRO členstvo.',
    freelancersOfferCta: 'Klik sem.',
    freelancersOfferMobile: '-25 % na PRO členstvo',
  },
  ads: {
    title: `REKLAMÉ PLOCHY <br />PRETLAK ADS!`,
    price: `Cena od 250 € <span class="addon">+ DPH</span>`,
    views: '{{count}} zobrazení webu za mesiac',
    social: '{{count}} fans na sociálnych sieťach',
    newsletter: '{{count}} odberateľov newslettra',
    description: `Zviditeľnite svoju značku cez naše reklamné plochy, ktoré cielia presne tam, tak potrebujete. Ponúkame rôzne <strong>webové formáty, sociálne siete alebo newsletter</strong>.`,
    cta: 'Zobraziť cenník',
  },
  hotCompany: {
    label: '🔥 HOT',
    company: 'firma',
    text1:
      'Buďte HOT a získajte viac! Čerpajte z benefitov ktoré vašu firmu dostanú do popredia. Pre viac info nám',
    email: 'napíšte',
    text2: 'alebo zavolajte na',
  },
  discountValue: 'Zľava -{{value}}%',
}
