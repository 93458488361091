import React, { FC } from 'react'
import { useRouter } from 'next/router'
import TrackingUtils from 'utils/tracking'
import useTranslation from 'translations/hooks/useTranslation'

type IProps = {
  appType: string
}

const TopBar: FC<IProps> = ({ appType }) => {
  const { t } = useTranslation()
  const router = useRouter()

  const trackClick = (type: string) => {
    TrackingUtils.event('topbar-click', {
      type: type,
    })

    switch (appType) {
      case 'freelancers':
        router.push('/freelancers/pro')
        break
      default:
        window.open('https://bratiska.sk/vysledky-vyhladavania?search=pretlak', '_blank')
    }
  }

  if (appType === 'freelancers') {
    return (
      <div className="top-bar">
        <div
          className="top-bar__item top-bar__item--freelancers-offer"
          onClick={() => trackClick('merch-banner')}
        >
          <span className="desktop">
            {t('common.topBar.freelancersOffer')}{' '}
            <span className="underline">{t('common.topBar.freelancersOfferCta')}</span>
          </span>

          <span className="mobile">{t('common.topBar.freelancersOffer')}</span>
          <span className="mobile-small">
            {t('common.topBar.freelancersOfferMobile')}
          </span>
        </div>
      </div>
    )
  }

  return (
    <div className="top-bar">
      <div
        className="top-bar__item top-bar__item--merch"
        onClick={() => trackClick('merch-banner')}
      >
        <span className="desktop">
          {t('common.topBar.merch')}{' '}
          <span className="underline">{t('common.topBar.merchUnderline')}.</span>
        </span>

        <span className="mobile">{t('common.topBar.merchMobile')}</span>
      </div>
    </div>
  )
}

export default TopBar
