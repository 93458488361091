module.exports = {
  title: 'PRO členstvo',
  membership: 'Členstvo',
  loginAdvantagesTitle: 'Pridaj sa medzi freelancerov a získaš',
  loginAdvantages: `– Vlastný profil úplne zadarmo (navždy)
    <br />
    – Miesto medzi freelancerskou špičkou na Slovensku
    <br />
    - Viditeľnosť u viac ako {{count}} firiem`,
  noFreelancerFound: 'Žiadny freelancer nebol nájdený.',
  companyUpsellHeading: 'Najlepšia <br/>databáza <br/>freelancerov',
  hpUpsellHeading: 'Objav <br/>pretlak {{count}} <br/>freelancerov',
  hpUpsellHeadingWithoutNumber: 'Objav <br/>pretlak <br/>freelancerov',
  showAllCategoryWithCount: {
    developeri: 'Zobraziť všetkých {{count}} developerov',
    dizajneri: 'Zobraziť všetkých {{count}} dizajnérov',
    marketeri: 'Zobraziť všetkých {{count}} marketérov',
    nontech: 'Zobraziť všetkých {{count}} non-tech',
    kreativci: 'Zobraziť všetkých {{count}} kreatívcov',
  },
  showAllCategory: {
    developeri: 'Zobraziť všetkých developerov',
    dizajneri: 'Zobraziť všetkých dizajnérov',
    marketeri: 'Zobraziť všetkých marketérov',
    nontech: 'Zobraziť všetkých non-tech',
    kreativci: 'Zobraziť všetkých kreatívcov',
  },
  addFavourite: 'Pridať medzi obľúbených',
  removeFavourite: 'Odstrániť z obľúbených',
  showAllCount: 'Zobraziť všetkých {{count}}',
  showAll: 'Zobraziť všetkých',
  showAllFreelancers: 'Zobraziť všetkých freelancerov',
  showFreelancers: 'Zobraziť freelancerov',
  weAreSadThatYouAreLeaving:
    'Je nám ľuto, že odchádzaš, ale taký je asi život. Držíme palce a keby niečo, vieš, kde nás nájdeš.',
  contactToFreelancerInMail: 'Správa freelancerovi bola úspešne odoslaná!',
  openingPrice: 'UVÁDZACIA CENA',
  displayForOneMonth: 'Zobrazenie na 1 mesiac',
  uncoverSelectedCategory: 'Odkrytie len 1 vybranej kategórie',
  currentlyUnavailable: 'Momentálne nedostupné',
  unlockFreelancers: 'Odomknúť freelancerov!',
  unlockFreelancersNumber: '{{count}} freelancerov, zadarmo!',
  unlockFreelancersModalDescription:
    'Zobrazenie ďalších freelancerov je podmienené prihlásením alebo registráciou.',
  unlockFreelancersCta: 'Odomknúť freelancerov',
  next: 'Ďalší',
  contact: 'Kontaktovať',
  favourite: {
    title: 'Obľúbený',
    titleMany: 'Obľúbení',
    empty:
      'Zatiaľ tu nič nemáte. Kliknutím na tlačidlo ❤️ pri profile freelancera, ho pridáte do obľúbených.',
  },
  bonus: {
    promoCodeExpiry: 'Platnosť promokódu',
    headline1: 'Zľava 30 %',
    headline2: 'Zľava na štúdium',
    headline3: '3 mesiace hostingu ZADARMO',
    headline4: 'Lifetime zľava 15 %',
    headline5: 'Zľava 15 % na FLEXI/FIX DESK',
    item1:
      'Začni fakturovať rýchlo a moderne. S Billdu to je hračka. Teraz aj so zľavou 30 % na všetky plány.',
    item2:
      'Absolvuj 130 hodín marketingových tipov a rád. Prihlás sa na polročné štúdium s 10 % zľavou alebo ročné MBA štúdium s 5 % zľavou. Študuj kedykoľvek a kdekoľvek.',
    item3:
      'Spusti svoj web ešte dnes. Pri objednávke The Hosting Start (100 GB) na 1 rok získaš 3 mesiace naviac.',
    item4:
      'Trovi môže byť tvoje posledný nástroj na fakturáciu, trackovanie projektov a na ďalšie mzdové a právne služby. Poď ho vyskúšať aj s našou exkluzívnou lifetime zľavou - 15%.',
    item5:
      'Hľadáš kvalitný priestor pre tvoju prácu? Pridaj sa do jedinečnej HubHub komunity aj s 15 % zľavou! Stačí ak klikneš na tlačidlo nižšie a požiadaš o FLEXI/FIX DESK členstvo s promokódom.',
  },
  statusText: {
    waiting: '🕔 Čaká na schválenie',
    unpublished: '⛔️ Skrytý',
    banned: '⛔️ Zamietnutý',
    online: '✅ Online',
  },
  added: 'Pridaný',
  seen: 'Videný',
  categoryName: {
    marketeri: {
      one: 'marketér',
      other: 'marketéri',
      many: 'marketérov',
      for: 'marketérov',
      whom: 'marketéra',
    },
    developeri: {
      one: 'developer',
      other: 'developeri',
      many: 'developerov',
      for: 'developerov',
      whom: 'developera',
    },
    kreativci: {
      one: 'kreatívec',
      other: 'kreatívci',
      many: 'kreatívcov',
      for: 'kreatívcov',
      whom: 'kreatívca',
    },
    dizajneri: {
      one: 'dizajnér',
      other: 'dizajnéri',
      many: 'dizajnérov',
      for: 'dizajnérov',
      whom: 'dizajnéra',
    },
    nontech: 'non-tech',
    other: 'Ostatné',
  },
  hiddenProfile: 'Skrytý profil',
  picture: 'Fotka',
  background: 'Vybrať farbu profilu',

  proModal: {
    title: 'Využívaj PRO členstvo naplno!',
    description: 'Pridaj svoju fotku, video a zmeň farbu svojho profilu.',
    submit: 'Dokončiť',
  },
  invisibleProfileNotebox: {
    heading: 'Neviditeľný profil. Teraz ťa nikto nevie nájst!',
    text: 'Tvoj profil sa zobrazuje iba ľuďom, ktorým pošleš link. Prejdi na PRO a uvidia ťa všetci.',
  },
  invisibleProfile: 'Neviditeľný profil',
  pro: {
    label: '🔥 PRO',
    discountPill: 'ZĽAVA -25% Platí len do konca roka!',
    monthlyCta: 'Mesačná platba za {{price}} / mesiac',
    name: 'Freelancer',
    editSubscription: 'Upraviť členstvo',
    editSubscriptionLong: 'Upraviť PRO členstvo',
    microsite: {
      title: 'Pridaj sa do zoznamu freelancerov',
      description:
        'S PRO členstvom čerpáš benefity, ktoré ťa dostanú do popredia.<br />Viac očí, viac práce, viac pláce.',
      featuresTitle: 'PRO ČLENSTVOM<br />ZÍSKAŠ',
      features: {
        web: {
          title: 'Zverejnenie na webe',
          description:
            'S PRO členstvom ťa uvidia všetci, pretože tvoj profil sa zobrazuje verejne na webe. FREE profil je viditeľný len cez link.',
        },
        photo: {
          title: 'Fotka pri tvojom mene',
          description:
            'Prvý dojem je len jeden. Žmurk, úsmev, vážna tvár alebo GIF-ko? Je to na tebe.',
        },
        video: {
          title: 'Video v profile',
          description:
            'Stále je lepšie raz vidieť, ako stokrát čítať. Pridaj do svojho profilu video a ukáž všetkým, čo robíš!',
        },
        ppc: {
          title: 'PPC kampaň',
          description:
            'Tvoj profil odpromujeme aj v rámci online kampaní. Uvidia ťa tak potenciálni klienti aj mimo náš web.',
        },
        top: {
          title: 'TOP zobrazenie na webe',
          description:
            'Tvoj profil bude zobrazený na úvodnej stránke. Firmám tak budeš hneď na očiach.',
        },
        social: {
          title: 'Promo na sociálnych sieťach s viac ako 22k fans',
          description:
            'Venujeme ti poriadny pretlak na našom Facebooku a Instagrame a LinkedIn. Tvoj profil odprezentujeme formou príspevkov a stories.',
        },
        email: {
          title: 'Dostávaš ponuky na e-mail',
          description:
            'Nové ponuky na freelance práce ti chodia priamo na e-mail, takže ti žiadna spolupráca neutečie.',
        },
        color: {
          title: 'Podfarbenie profilu',
          description:
            'Odlíš svoj profil od ostatných a podfarbi si ho ako len chceš. Čím väčší úlet, tým lepšie.',
        },
      },
      plans: {
        title: 'Byť PRO sa oplatí',
        text: 'Prejdi na PRO členstvo a uvidia ťa všetci!',
        text2: 'Ideš do toho?',
      },
    },

    pricing: {
      description: 'Rovnako zaplatíš za 1 espresso',
      month: 'mesiac',
      year: 'rok',
      yearly: 'ročne',
      badge: '10x viac videní profilu',
      cta: 'Chcem byť PRO!',
      ctaSubscribed: 'Aktívne',
      features: {
        web: 'Zverejnenie na webe',
        photo: 'Fotka v profile',
        video: 'Video v profile',
        ppc: 'PPC kampaň',
        top: 'TOP zobrazenie na webe',
        social: 'Promo na sociálnych sieťach',
        color: 'Podfarbenie profilu',
      },
      noteFree: 'Profil sa zobrazí len cez priamy link',
      noteMonthly: 'Pravidelná platba 1-krát za mesiac',
      noteYearly: 'Pri ročnej platbe {{price}}',
      noteYearlyWithSale:
        'Pri ročnej platbe <span class="strike">{{priceOld}}</span> {{price}}',
    },
  },
  proOfferModal: {
    title: 'Tvoj profil je neviditeľný',
    description: 'Všetkým sa zobrazí len vtedy, keď prejdeš na PRO členstvo',
  },
  upsell: {
    heading: 'Až {{count}} <br/>freelancerov <br/>v hre!',
    text: 'Hľadáte marketéra, developera, či dizajnéra pre svoj projekt? Získajte prístup do najväčšej databázy freelancerov na trhu už od {{price}}&nbsp;€/mesiac.',
    detail: {
      note: 'Prejdi na PRO a získaj viac! Čerpaj z benefitov ktoré ťa dostanú do popredia.',
      cta: 'Zobraz viac info',
    },
    menu: 'Získaj viac!',
    profile: 'Získaj viac len za {{price}}/mesiac',
  },
  upsellHowToGoodProfile: {
    strong: 'Psst.',
    regular:
      'Ak chceš vymakať svoj profil freelancera, čekni tento rýchly návod v našom Magazíne.',
  },
  switcher: {
    monthly: 'Mesiac',
    yearly: 'Rok',
    note: 'Ušetrite 50%',
  },
  testimonial: {
    1: {
      text: 'Skúsenosť s Pretlakom mám nanajvýš skvelú! Za krátky čas mi prišlo viacero relevantných dopytov. Veľmi užitočná je aj funkcia dostupný / nedostupný, ktorú si viem ľahko upraviť podľa mojich aktuálnych kapacít.',
      textShort:
        'Skúsenosť s Pretlakom mám nanajvýš skvelú! Za krátky čas mi prišlo viacero relevantných dopytov.',
      name: 'Daniel Biras',
      position: 'PPC Specialist',
    },
    2: {
      text: 'Vďaka Pretlaku vás môže uvidieť veľmi veľa ľudí z kreatívneho priemyslu aj mimo neho. Odporúčam PRO členstvo aspoň skúsiť. Narastajúca klientela vás bude motivovať a vaše portfólio sa bude rozpínať, ako kvantitou, tak kvalitou - čo vás na konci dňa povzbudí ešte viac.',
      textShort:
        'Vďaka Pretlaku vás môže uvidieť veľmi veľa ľudí z kreatívneho priemyslu aj mimo neho.',
      name: 'Karolína Uherčíková',
      position: 'Ilustrátorka / dizajnérka',
    },
    3: {
      text: 'Za veľmi krátky čas sa mi vďaka PRO členstvu ozvalo niekoľko relevantných firiem.',
      textShort:
        'Za veľmi krátky čas sa mi vďaka PRO členstvu ozvalo niekoľko relevantných firiem.',
      name: 'Michal Slovák',
      position: 'Branding and Packaging',
    },
  },

  freelancersUnlock: {
    yearly: {
      title: 'Komplet rok',
    },
    monthly: {
      title: 'Komplet mesiac',
    },

    benefits: {
      unlock: 'Prístup k {{count}} freelancerom',
      email: 'Noví freelanceri e-mailom',
      cancel: 'Možnosť kedykoľvek zrušiť',
    },
  },

  contactModal: {
    emailLabel: 'Váš e-mail',
    emailPlaceholder: 'Sem zadajte váš e-mail',
    textareaLabel: 'Text správy',
    textareaPlaceholder:
      'Sem napíšte správu pre freelancera, poopis projektu, ponúkanú odmenu a pod.',
  },

  companyProfileUpsell: {
    title: 'Objavte pretlak {{count}}+ freelancerov!',
    text: 'Vyberajte ihneď z množstva dostupných freelancerov!',
    cta: 'Zobraziť freelancerov',
  },

  skillLevel: {
    plain: {
      graduate: 'Absolvent',
      junior: 'Junior',
      mid: 'Mid',
      senior: 'Senior',
      expert: 'Expert',
      standard: '',
    },
    detail: {
      graduate: '',
      junior: '(1 - 2 roky)',
      mid: '(3 - 4 roky)',
      senior: '(5 - 8 rokov)',
      expert: '(9+ rokov)',
      standard: '',
    },
  },
  availabilityStatus: {
    'not-available': 'Nedostupný',
    'available-less-30-hours': 'Menej ako 30 hodín / týždeň',
    'available-more-30-hours': 'Viac ako 30 hodín / týždeň',
    'available-agreement': 'Podľa potreby',
    'full-time-available': 'K dispozícii na full-time / kontrakt',
  },
  openToFullTime: 'K dispozícii aj na full-time / kontrakt',
  openToFullTimeShort: 'Full-time / kontrakt',
  profileModal: {
    title: 'Topuj svoj profil!',
    subtitle: '🔝 Vystreľ sa až na vrch zoznamu',
    description:
      'Je to rýchle a jednoduché. Stačí klik a tvoj profil sa zobrazí úplne hore pod pozíciou "odporúčame", až kým ťa nepreskočí niekto iný. 🚀',
  },

  upsellTop: {
    title: 'Topuj svoj profil!',
    description: 'Vystreľ sa až na vrch zoznamu! Je to rýchle a jednoduché.',
  },

  topProfileModal: {
    title: 'Topovať profil',
    product1: 'Topovať na webe',
    product2: 'Prezdieľať na sociálnych sieťach',
    product3: 'Zvýrazniť profil',
    proDescription: 'Získaj toto všetko len za 4,99€/mesiac. Prejdi na PRO!',
    tooltip: {
      product1:
        'Tvoj profil sa zobrazí úplne hore pod pozíciou "odporúčame" až kým ťa nepredbehne niekto iný.',
      product2:
        'Tvoj profil ukážeme viac ako 20-tisíc ľuďom cez náš Facebook, Instagram a LinkedIn.',
      product3:
        'Odlíš svoj profil od ostatných a zafarbi si ho akoukoľvek farbou. Čím väčší úlet, tým lepšie.',
      product2Default:
        'Dostupné len pre PRO freelancerov! Tvoj profil ukážeme viac ako 20-tisíc ľuďom cez náš Facebook, Instagram a LinkedIn.',
    },
  },
  activeNow: 'Teraz aktívne',
  availabilityModal: {
    description: 'Daj firmám vedieť, koľko máš aktuálne voľných kapacít.',
  },
  loginBanner: {
    title1: 'Prejdi<br />na',
    title2: 'a tvoj profil uvidia všetci',
    pill: 'Len teraz zľava 25 %',
    cta: 'Idem do toho!',
    valid: 'Platí len do konca roka',
  },
}
